<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-row type="flex" justify="center" class="bg">
      <el-col :md="12" :sm="20">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <h2>Benvenuto Sul Portale di ESD Italia</h2>
              <h4>Attivazione account</h4>
            </div>
          </template>
          <el-form label-width="auto" :model="activationForm" status-icon :rules="rules" ref="activationForm">
            <el-form-item :label="$t('activation.formLabels.userCode')" prop="userCode">
              <el-input v-model="activationForm.userCode"></el-input>
            </el-form-item>
            <el-form-item :label="$t('activation.formLabels.tmpPassword')" prop="tmpPassword">
              <el-input show-password v-model="activationForm.tmpPassword"></el-input>
            </el-form-item>

            <el-form-item label="Email" prop="email">
              <el-input v-model="activationForm.email"></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input type="password" v-model="activationForm.password"></el-input>
            </el-form-item>
            <el-form-item label="Conferma Password" prop="passwordConfirm">
              <el-input type="password" v-model="activationForm.passwordConfirm"></el-input>
            </el-form-item>
            <div style="display: flex; justify-content: flex-end;">
              <el-button block type="primary" @click="submit">{{$t("buttons.activate")}}</el-button>
            </div>
          </el-form>
        </el-card>
        <div style="padding: 5px">
          <el-link @click="$goTo('login')"> {{$t("activation.hint")}}  </el-link>
        </div>
      </el-col>
    </el-row>
    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {activateUser} from "../api/user.api";
import Header from "../components/Header";
import Footer from "../components/Footer";
// import router from "../router/router";

export default {
  components: {Footer, Header},
  data() {
    const validateEmail = (rule, value, callback) => {
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (value === '') {
        callback(new Error('Inserisci l\'indirizzo email'));
      } else {
        if(value.match(mailformat)) {
          this.$refs.activationForm.validateField("email");
        } else {
          callback(new Error('Formato non valido'));
        }
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Inserisi la password'));
      } else {
        if (this.activationForm.password.length >= 6) {
          this.$refs.activationForm.validateField("password");
        } else {
          callback(new Error('La password è troppo corta'));
        }
        callback();
      }
    };
    const validateConfirm = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Inserisci la password'));
      } else {
        if (this.activationForm.password === value) {
          this.$refs.activationForm.validateField("passwordConfirm");
        } else {
          callback(new Error('La password non coincide'));
        }
        callback();
      }
    };
    return {
      activationForm: {
        userCode: "",
        tmpPassword: "",
        password: "",
        passwordConfirm: "",
        email: "",
      },
      rules: {
        passwordConfirm: [
          {validator: validateConfirm, trigger: 'blur'}
        ],
        password: [
          {validator: validatePassword, trigger: 'blur'}
        ],
        email: [
          {validator: validateEmail, trigger: 'blur'}
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["isLogged", "getUserActivationData", "isFirstAccess"])
  },
  methods: {
    ...mapActions(["activateUser", "login"]),
    async submit() {
      let valid = await this.$refs.activationForm.validate();
      if (valid) {
        let response = await activateUser({...this.activationForm});
        if(response){
          this.$message(response.data.message);
          this.login({email: this.activationForm.email, password: this.activationForm.password}).then(
              () => {
                if (this.isLogged) {
                  this.$router.push("dashboard");
                }
              }
          )


          // window.setTimeout(function (){
          //   router.push("login");
          // }, 3000)
        }
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.activationForm.userCode = this.getUserActivationData.email??'';
    this.activationForm.tmpPassword = this.getUserActivationData.password??'';
  }
}
</script>

<style scoped>
.bg {
  height: 100vh;
  background: #ededed;
  width: 100%;
  align-items: center;
}


</style>
